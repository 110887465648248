import React, { useState } from 'react';
import { Link } from "gatsby"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Article from "../components/article"
import getLanguage from "../utilt/getLangueage"

import useWindowSize from "../utilt/useWindowSize"

//CSS
import { aboutButton } from "./about.module.css"
import { aboutMail } from "./about.module.css"
import { article } from "./about.module.css"
import { aboutDesc } from "./about.module.css"
import { aboutName } from "./about.module.css"
import { aboutName2_wrap } from "./about.module.css"
import { aboutName2 } from "./about.module.css"
import { flexCenter } from "./about.module.css"
import { aboutSocials } from "./about.module.css"
//import { profile } from "./about.module.css"
//import { occupation } from "./about.module.css"

import portrait01 from "../images/about/3portrait.png"
import portrait02 from "../images/about/katotenPortrait.jpg"
import facebookLogo from "../images/scLogo_facebook.png"
//import twitterLogo from "../images/scLogo_twitter.png"
import instagramLogo from "../images/scLogo_instagram.png"
import noteLogo from "../images/scLogo_note.png"
import neortLogo from "../images/scLogo_neort.png"
import mailLogo from "../images/scLogo_mail.png"

//import seoImg from "../images/seo/seo_about.jpg"


/**
 * ポートレイト画像
 */
const Portrait = props => {
  return (
    <img
      src={props.pic}
      alt={props.alt}
      style={{
        maxWidth: `${256 - Math.max(320 - props.winSize.width, 0)}px`,
        height: `256px`,
        borderRadius: "50%",
        border: "2px solid #111111",
      }}
    />
  )
}

/**
 * ソーシャルロゴ
 */
const ScLogo = props => {
  return (
    <a
      href={props.href}
    >
      <img
        src={props.logo}
        alt={props.alt}
        title={props.alt}
        style={props.hide ? {
          width: 0,
          height: 0,
          margin: 0,
        } : {
          maxWidth: `48px`,
          margin: `4px`
        }}
      />
    </a>
  )
}

const ScLogoSpan = props => {
  return (
    <button
      className={aboutButton}
      onClick={() => alertMailAdress()}
    >
      <img
        src={props.logo}
        alt={props.alt}
        title={props.alt}
        className={aboutMail}
        style={{
          maxWidth: `48px`,
          margin: `4px`
        }}
      />
    </button>
  )
}

function alertMailAdress() {
  alert("info@drawinghell.com");
}

/**
 * ページ
 */
const About = (props) => {
  const [lang, setLang] = useState(getLanguage());
  function setLanguage(enOrJa) {
    setLang(enOrJa);
  }
  const winSize = useWindowSize() || props.size || { width: 640, height: 948 }


  return (
    <Layout
      checked="about"
      winSize={winSize}
    >
      <Seo
        title="About"
        description={lang !== "ja" ?
          "About the author" :
          "作者について"
        }
        lang={lang}
        image="https://www.drawinghell.com/ogp_images/seo_about.jpg"
        pathname="/about"
      />
      <Article
        isLangBar="true"
        winSize={winSize}
        setLang={setLanguage}
        lang={props.lang}
      >
        <div
          className={article}
        >
          <Portrait
            winSize={winSize}
            pic={portrait01}
            alt="３'s portrait"
            margin="0"
          />
          <div
            className={aboutDesc}
          >
            <h4 className={aboutName}>
              {lang !== "ja" ? "３" : "３"}
            </h4>
            <div
              className={aboutName2_wrap}
            >
              <p className={aboutName2}>
                {lang === "ja"
                  ? 'ドローイング作家'
                  : "Drawing Artist"}
              </p>
            </div>

          </div>
        </div>



        <div
          className={flexCenter}
        >
          <div
            className={aboutSocials}
          >
            <ScLogo
              logo={noteLogo}
              href="https://note.com/drawinghell/"
              alt="note"
              winSize={winSize}
              hide={lang !== "ja" ? true : false}
            />
            <ScLogoSpan
              logo={mailLogo}
              alt="mail address"
              winSize={winSize}
            />
          </div>
        </div>



      </Article>
    </Layout>
  )
}
export default About

/*

            <a href="https://note.com/drawinghell/n/n7a41edb8d42e"
              target="_blank"
              rel="noopener noreferrer"
              style={
                { margin: `5px 0 8px` }
              }
            >
              {lang !== "ja" ? "Statement as an artist (Japanese)" : "作家としてのステートメント"}
            </a>
        <div
          style={{
            padding: "0 5%",
            margin: "0.5rem 0"
          }}>
          <hr />
        </div>

        <div
          className={article}
          style={{
            marginTop: "2rem",
            marginBottom: "1.5rem",
          }}
        >
          <Portrait
            winSize={winSize}
            pic={portrait02}
            alt="Katoten's portrait"
          />
          <div
            className={aboutDesc}
          >
            <h4 className={aboutName}>
              {lang !== "ja" ? "katoten" : "カトテン"}
            </h4>
            <div
              className={aboutName2_wrap}
            >
              <p className={aboutName2}>
              {lang !== "ja" ? "Alias for content creation" : "コンテンツ作成用に作った別名"}
              </p>
            </div>
            <Link
              to={
                "/cartoontengoku"
              }
              style={
                { margin: `5px 0 0px` }
              }
            >
              cartoontengoku
            </Link>
          </div>
        </div>



            <ScLogo
              logo={instagramLogo}
              href="https://www.instagram.com/drawinghell.3/"
              alt="instagram"
              winSize={winSize}
            />
            <ScLogo
              logo={facebookLogo}
              href="https://www.facebook.com/drawinghell"
              alt="facebook"
              winSize={winSize}
            />
            <ScLogo
              logo={neortLogo}
              href="https://neort.io/@Kiz7VIowEkUqUJNuzjLKUPpLxqs1"
              alt="NEORT"
              winSize={winSize}
            />


        <div
          className={profile}
        >
          <p >
            {lang !== "ja" ? 'Selected Solo Exhibitions' : "主な個展"}
          </p>
          <p>
            {lang !== "ja" ? '2002 TokyoWonderSite,Tokyo' : "2002年 トーキョーワンダーサイト"}
          </p>
          <p>
            {lang !== "ja" ? '2003 Tokyo Metropolitan Government Office,Tokyo' : "2003年 東京都庁"}
          </p>
          <p>
            {lang !== "ja" ? '2004 TokyoWonderSite,Tokyo' : "2004年 トーキョーワンダーサイト"}
          </p>
          <p>
            {lang !== "ja" ? '2007 Musashino Art University Museum & Library Folk archive gallery,Tokyo' : "2007年 武蔵野美術大学美術資料図書館民俗資料室ギャラリー"}
          </p>
        </div>
          <div
            className={aboutDesc}
          >
            <h4 className={aboutName}>
              {lang !== "ja" ? "Mizuno Ryo(３)" : "水野 亮（３）"}
            </h4>
            <p>
            {lang !== "ja" ? 'Drawing pictures' : "絵を描いています"}
          </p>
          </div>
          
        <div
          className={occupation}
        >
          <p >
            {lang !== "ja" ? 'Artist' : "絵を描いています。"}
          </p>
        </div>

            <p >
              {lang !== "ja" ? 'Artist' : "美術家"}
            </p>

            <p
              style={{
                marginBottom: "0.2rem"
              }}>
              {lang !== "ja" ? "Alias" : "別名"}
            </p>
  //Twitter

                        <ScLogo
              logo={twitterLogo}
              href="https://twitter.com/drawinghell"
              alt="twitter"
              winSize={winSize}
            />


            //カトテン
                    

*/